import React from 'react'

const Developer = () => {
  return (
    <div className='container'>
        <div className='row justify-content-evenly my-5'>

            <div className='col-11'>
                <div className='descrip'>
                    <h6 className=''>This Website is Developed By the Following Team Members.</h6>
                </div>
            </div>

            <div className='col-11'>
                <div className='row justify-content-evenly my-3'>
                <div className='col-4 p-1'>
                        <div className="card teacherProfile bg-light p-3" style={{height:"450px"}}  >
                            <img src="./images/mahmood.png" className="card-img-top teacherProfileImage" alt="..."  />
                            <h5  className='text-center mt-4'>Supervisor</h5>
                            <div className="card-body teacherCardBody ">
                                <h6 className="card-title"><span >Name:</span> <span>Mahmood Ul Hassan</span></h6><hr />
                                <h6 className="card-title"><span >Designation: </span> Lecturar</h6><hr />
                                <h6 className="card-title"><span >Mobile:</span> 0300-7240980</h6>
                                
                            </div>                            
                        </div>   
                    </div>

                    <div className='col-4 p-1'>
                        <div className="card teacherProfile bg-light p-3" style={{height:"450px"}}  >
                            <img src="./images/asad.png" className="card-img-top teacherProfileImage" alt="..."  />
                            <h5  className='text-center mt-4'>Member 1</h5>
                            <div className="card-body teacherCardBody ">
                                <h6 className="card-title"><span >Name:</span> Muhammad Asad Ullah</h6><hr />
                                <h6 className="card-title"><span >Web Developer: </span> ( React Js & PHP )</h6><hr />
                                <h6 className="card-title"><span >Mobile:</span> 0307-6791417</h6>
                                
                            </div>                            
                        </div>   
                    </div>

                    <div className='col-4 p-1'>
                        <div className="card teacherProfile bg-light p-3" style={{height:"450px"}}  >
                            <img src="./images/ghulam.jpg" className="card-img-top teacherProfileImage" alt="..."  />
                            <h5  className='text-center mt-4'>Member 2</h5>
                            <div className="card-body teacherCardBody">
                                <h6 className="card-title"><span >Name:</span> Muhammad Ghulam Hussain</h6><hr />
                                <h6 className="card-title"><span >Web Developer:  </span> ( SEO & PHP )</h6><hr />
                                <h6 className="card-title"><span >Mobile:</span> 0322-5201190</h6>
                                
                            </div>                            
                        </div>   
                    </div>

                    
                </div>
            </div>

        </div>
    </div>
  )
}

export default Developer