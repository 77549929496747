import React,{useEffect,useState} from 'react';
import axios from "axios";
import "../Components/News.css";
import { Link } from 'react-router-dom';
import Pageheader from "../Components/Pageheader";



const NewsPage = () => {
    const [latestnews,setLatestnews] = useState([])
    useEffect(() => {
        axios.get('https://www.gmdc.edu.pk/admin-panel/latest_news_api.php')
        .then(response => {
            setLatestnews(response.data);
            // console.log(response.data);
        })
        .catch(error => {
            console.log(error)
        })
    },[])
    
  return (
    <>
    <Pageheader title="Latest News"/>
        <div className="container">
            
           <div className="row justify-content-evenly my-5">
           { 
                    latestnews.length > 0 ? 
                    latestnews.map(val => 
                    <div className="col-md-12 col-10 shadow my-3 rounded" key={val.id}>
                        <div className=" my-2" key={val.id}>
                            <div className="">
                                <h5>{val.news_title}</h5>
                                <div className="nextPagenews">
                                    <p className='para'>{val.news_description.substring(0, 350)}
                                    </p>
                                    
                                </div>
                                
                                <div className=" ">
                                
                                </div>
                                <p className='text-end text-secondary'>  <span className='fw-bold'><Link to={`/morenews/${val.id}`} className='newsreadmore  btn-sm ms-4 btn-primary'> Read More</Link> Dated on: </span> {val.news_date}</p>

                            </div>
                            <div>   
                            </div>
                        </div> 
                    </div>                         
                    )
                    : null 
                    } 
            </div>                  
        </div>
    </>
      )
    }
export default NewsPage;