import React, {useState } from 'react'
import Pageheader from '../Components/Pageheader';
import {useParams} from "react-router-dom";
import { useEffect } from 'react';
import axios from 'axios';

const Morenews = () => {
  const para = useParams();
  const [file,setFile] = useState({
    id:"",
    news_description:"helo",
    news_date:"",
    news_image:"",
    news_title:""
  });
  useEffect(()=>{
    axios.get(`https://www.gmdc.edu.pk/admin-panel/latest_news_api.php`)
    .then(response => {
      const ndata = response.data;
      var fdata = ndata.filter(vale => ( 
        vale.id === para.id
      ))
      // console.log(fdata)
      setFile(fdata)
    }) 
    .catch(error => {
      console.log(error)
    })
  },[])
  // console.log(file)

  return (
    <div className='Morenews'>
        <Pageheader title="More News" />
        <div className="container">
          <div className="row justify-content-evenly">
          { 
                    file.length > 0 ? 
                    file.map(val => (
                            <div className="file my-2" key={val.id}>
                                <div className="">
                                    <p className='text-end'><span className='fw-bold'><span className='border-bottom'>Dated:  {val.news_date} </span></span></p>
                                    <h4 className='my-3'><span className='bg-success text-white px-2 rounded'>Title:</span> {val.news_title}</h4>
                                    <div className=" my-3">
                                        <h5>Details About This News</h5>
                                        <p className=''>{val.news_description} </p>
                                    </div>
                                </div>
                                <div className="singleNewImage text-center">
                                  <embed src={`https://www.gmdc.edu.pk/admin-panel/images/${val.news_image}`} width="90%" style={{margin:"auto"}} height="700px" allowFullScreen />
                                </div>
                                
                                <div>   
                                </div>
                            </div>                        
                            )
                    )
                : null 
                }
          </div>
        </div>
      </div>
  )
}

export default Morenews