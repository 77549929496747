import axios from 'axios';
import React,{useState} from 'react';
import { useEffect } from 'react';

const IntroVideo = () => {
  const [link, setLink] =  useState([])
  useEffect( () => {
      axios.get("https://www.gmdc.edu.pk/admin-panel/intro_video_api.php")
      .then(response => {
        setLink(response.data);
      })
      .catch(error => {
        console.log(error);
      })
  },[])
  return (
    <>
    {
      link.length ? 
      link.map(value => (
        <div className="main_video" key={value.id}> 
          <iframe className='heightIntro' style={{width:"100%",height:"450px"}} src={`https://www.youtube.com/embed/${value.intro_video}`} title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div>
      ))
      : null
    }
    </>
  )
}

export default IntroVideo