import React from 'react'
import "./Departmentcard.css";
import { Link } from 'react-router-dom';


const Departmentcard = (props) => {
    
    return(
        <div className="col-lg-3 col-md-4 col-sm-6 col-9 my-2" key={props.id}>
                <div className="cardselfdiv text-center" >
                    <h5 className='text-center py-2'>{props.title}</h5>
                    <div className="cardselfimg px-2">
                        <img src={`https://www.gmdc.edu.pk/admin-panel/images/${props.d_logo}`} alt="" />
                    </div>
                    <Link to={`/singledepartment/${props.id}`} className='departlink'><button className='btn btn-primary my-3 px-5 text-center'>Detail</button>
                    </Link>
                </div>    
            </div>
    )
}

export default Departmentcard
