import React from "react";
import { Link } from "react-router-dom";
// import footerlogo from "../Images/logo2.png";

import "./Header.css";
const Header = () => {
    return( 
        <>
            <div className="main_navbar">
                <nav className="navbar navbar-expand-lg navbar-light" id="mynav">
                    <div className="container">
                            <Link  to="/"><div className="footer_logo1">
                                <img src="../Images/logo2.png" alt="footerlogo1" className="headerimg1"/>
                                <div className="logotext1 ">
                                    <h5>Government Municipal <br/> Graduate College</h5>
                                </div>
                            </div></Link>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0" id="myLink">
                            <li className="nav-item">
                            <Link  className="nav-link " aria-current="page" to="/">Home</Link>
                            </li>
                            <li className="nav-item">
                            <Link  className="nav-link" to="/gallery">Gallery</Link>
                            </li>
                            <li className="nav-item">
                            <Link  className="nav-link" to="/news">News</Link>
                            </li>
                            <li className="nav-item dropdown myhover" >
                            <Link  className="nav-link" to="/department" id="navbarDropdown" role="button"  aria-expanded="false" >
                                Department
                            </Link>
                            
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><Link  className="dropdown-item" to="/department">Department</Link></li>
                                <li><Link  className="dropdown-item" to="/programme">Programme</Link></li>
                                <li><Link  className="dropdown-item" to="/nonteaching">Non Teaching Staff</Link></li>
                            </ul>
                            </li>
                            <li className="nav-item dropdown myhover" >
                            <Link className="nav-link" to="/mission" id="navbarDropdown" role="button"  aria-expanded="false">
                                About Us
                            </Link>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><Link className="dropdown-item" to="/mission">About Us</Link></li>
                                <li><Link className="dropdown-item" to="/glimpse">Glimpse Of Collefe</Link></li>
                                <li><Link className="dropdown-item" to="/rule">Rule & Regulation</Link></li>
                                <li><Link className="dropdown-item" to="/library">Library Rules</Link></li>
                                <li><Link className="dropdown-item" to="/developer">Development Team</Link></li>
                            </ul>
                            </li>
                            <li className="nav-item">
                            <Link className="nav-link" to="/contact">Contact Us</Link>
                            </li>
                        </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </>
    )
}
export default Header;