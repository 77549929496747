import React from "react";
import "./Mission.css";
import Subheading from "../Components/Subheading";
import Pageheader from "../Components/Pageheader";
const Mission = () => {
    return(
        <>
        <Pageheader title="Mission Statement"/>

        <div className="container">
            <div className="row my-5">
                <div className="col-md-6">
                    <div className="qauidmissionimage">
                        <img src="./Images/missionstatement.jpg" alt="qauid" />
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="missiontext">
                    <Subheading title="Our Mission"/>
                        <div className="missiondescription">
                           <div className="styledborder">
                           <h6>We aim at providing our students with a highly competitive learning enviorment where concepts are inculcated and research is encouraged. We are striving to broaden minds and ennoble intellectual approach of our students enabling them to stand out in the world</h6>
                           </div>
                        </div>
                    </div>
                </div>
            </div><hr />

            <div className="row my-5">

                <div className="col-md-6">
                    <div className="missiontext">
                        <Subheading title="Qauid-e-Aam"/>
                        <h6 className="text-center">September 26, 1947</h6>
                        <div className="missiondescription">
                           <div className="styledborder">
                           <h6>Education is a matter of life and death for Pakistan. The world is progressing so rapidly that without requisite advance in education, not only shall we be left behind but wiped out altogether.</h6>
                           </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-6 my-3">
                    <div className=" qauidmissionspe">
                        <img src="./Images/Qauid.JPG" className="w-100" alt="qauid" />
                    </div>
                </div>
            </div><hr />

            <div className="row my-5">
                <div className="col-md-6 my-3">
                    <div className="qauidmissionspe">
                        <img src="./Images/allama.JPG" className="w-100" alt="allama" />
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="missiontext">
                    <Subheading title="علامہ محمد اقبال" />
                        <div className="missiondescription">
                           <div className="styledborder">
                                <h6 className="poetry">
                                    <p className="fs-4 text-end">ہردورمیں تعلیم ہے امراضِ ملت کی دوا</p>
                                    <p className="fs-4 text-end"> خونِ فاسد کے لیے تعلیم ہے مثل نیشتر</p>
                                </h6>
                           </div>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
export default Mission;