import React from "react";
import "./Glimpse.css";
import Subheading from "../Components/Subheading";
import Pageheader from "../Components/Pageheader";
const Rule = () => {
    return(
        <>
            <Pageheader title="Rule & Regulations"/>
            <div className="container">
                <div className="row justify-content-evenly my-5">
                    <div className="col-md-12  col-11">
                        <div className="admissionProcess">
                            <Subheading title="Admission Process"/>
                            <div className="innerTextAdmission mt-5 mx-3">
                                <p><strong>The candidate must come up to the required merit determined from time to time.</strong></p>
                                <p>The candidate has to appear in person for interview before the Admission Committee appointed by the Principal.</p>
                                <p><strong>The candidate has to present the following documents in original at the time of interview:</strong></p>
                                <ul>
                                    <li>Written permission from father / Guardian to seek admission to the college Affidavit declaring that the student will not participate in any political activities during college hours.</li>
                                    <li>Testimonials / Certificates of Boards / Universities showing marks / results / grade / percentage / CGPA of the last Examination(s).</li>
                                    <li>Passports size photos (3 for 1st Year), (4 for 3rd Year)</li>
                                    <li>Character Certificate / Provisional Certificate of School / Collegelast attended.</li>
                                    <li>N.I.C. of father.</li>
                                </ul>
                                <h3><strong>NOTE:</strong></h3>
                                <p>All the testimonials should be enclosed with the prescribed/printed admission form available in the prospectus. The class / qyota / category in which admission is sought must be clearly marked.</p>
                            </div>

                            <div className="uniform mt-5">
                                <Subheading title="College Uniform"/>
                                <div className="row justify-content-evenly my-5">
                                    <div className="col-md-6 p-1">
                                        <div className="inneruniform mt-5 mx-3">
                                            <p><strong>The following uniform is prescribed for the students of the college:</strong></p>
                                            <h4>Intermediate Students:</h4>
                                            <p><strong>Summer: </strong> Sliver grey trousers, white shirt,Blue Tie, black shoes.</p>
                                            <p><strong>Winter: </strong> Sliver grey trousers, white shirt, Navy blue Blazer / jersey, black shoes.</p>
                                            <h4><strong>NOTE:</strong></h4>
                                            <p>Fine at the rate of Rs. 25 per day is levied on violation of the uniform.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="Uniform justify-content-evenly d-flex">
                                            <img src="./Images/interuniform.JPG" className="w-50 " alt="" />
                                        </div>
                                    </div>
                                </div>

                                <div className="row justify-content-evenly my-5">
                                    <div className="col-md-6 p-1">
                                        <div className="inneruniform mt-2 mx-3">
                                            <p><strong>The following uniform is prescribed for the students of the college:</strong></p>
                                            <h4>BS (Hons) Students:</h4>
                                            <p><strong>Summer: </strong> Sliver grey trousers, white shirt,Maroon Tie, black shoes.</p>
                                            <p><strong>Winter: </strong> Sliver grey trousers, white shirt, Maroon Blazer / jersey, black shoes.</p>
                                            <h3><strong>NOTE:</strong></h3>
                                            <p>Fine at the rate of Rs. 25 per day is levied on violation of the uniform.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="Uniform justify-content-evenly d-flex">
                                            <img src="./Images/bsuniform.JPG" className="w-75 " alt="" />
                                        </div>
                                    </div>
                                </div>
                        
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Rule;