import React from "react";
import "./Programme.css";
import Pageheader from "../Components/Pageheader";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
const Programme = () => {
    const [file,setFile] = useState([])
    useEffect(() => {
        axios.get("https://www.gmdc.edu.pk/admin-panel/programme_api.php")
        .then(response => {
            console.log(response.data)
            setFile(response.data)
        })
        .catch(error => {
            console.log(error)
        })
    },[])
    return(
    <div className="programmain">
        <Pageheader title="Programme"/>
        <div className="container ">
            <div className="row my-5 ">
                <div className="col-lg-12 ">
                    <div className="teachingstaffinner table-responsive">
                        <table className="table table-hover  table-striped table-bordered ">
                            <thead className="">
                                <tr>
                                <th width="3%">Sr.#</th>
                                <th width="15%">Name</th>
                                <th width="10%">Study System</th>
                                <th width="10%">Available Time</th>
                                <th width="10%">Duration</th>
                                <th width="10%">Level</th>
                                </tr>
                            </thead>
                            <tbody>
                           {
                            file.length > 0 ? 
                            file.map((value,index) => (
                                <tr key={index}>
                                <th scope="row">{index + 1}</th>
                                <td>{value.programme_name}</td>
                                <td>{value.study_system}</td>
                                <td>{value.available_time}</td>
                                <td>{value.programme_duration}</td>
                                <td>{value.programme_level}</td>
                            </tr>
                            ))
                            : null
                           }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}
export default Programme;