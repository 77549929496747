import axios from 'axios'
import React from 'react'
import { useEffect } from 'react'
import "./PersonalityMessage.css";
import { useState } from 'react'

const PersonalityMessage = () => {
    const [personality,setPersonality] = useState([])
    useEffect(() => {
        axios.get("https://www.gmdc.edu.pk/admin-panel/personality_message_api.php")
        .then(response => {
            setPersonality(response.data);
            // console.log(response.data)
        })
        .catch(error => {
            console.log(error)
        })
    },[])
  return (
            <div className=" mainTest my-5 container">
                <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel" >
                    <div className="carousel-inner ">
                        {
                            personality.length > 0 ? 
                            personality.map((value,index) => (
                                <div key={value.id} className={index == 0 ? "carousel-item active" : "carousel-item "}>
                                    <div className="row justify-content-evenly">
                                        <div className="col-12 col-sm-9 col-lg-6">
                                            <div className="mainMassage">
                                                <div className="testimonial">
                                                    <div className="pic border bordered-info">
                                                        <img src={`https://www.gmdc.edu.pk/admin-panel/images/${value.personality_image}`} className="w-100 h-100" alt="image"/>
                                                    </div>
                                                    <h3 className="title">{value.personality_name}</h3>
                                                    <p className="description">
                                                    {value.person_message.substring(0, 300)}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                            ))
                        : 
                        null
                        }
                        
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true">  </span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
        </div>

  )
}

export default PersonalityMessage