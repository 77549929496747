import React from 'react';
import { useState,useEffect } from 'react';
import {useParams} from "react-router-dom";
import "./Singlegallery.css";
import axios from 'axios';

const Singlegallery = () => {
  const checkImage = (e) => {
    
  }
  const para = useParams();
  const [size,setSize] = useState({
    width:"100%",
    height:"400px"
  });
  // console.log(para)

  const [dataValue,setDataValue] = useState([]);
  useEffect(()=>{
    axios.get(`https://www.gmdc.edu.pk/admin-panel/latest_event_api.php`)
    .then(response => {
      const ndata = response.data;
      var fdata = ndata.filter(vale => ( 
        vale.id === para.id
      ))
      // console.log(fdata)
      setDataValue(fdata)
    })
    .catch(error => {
      console.log(error)
    })
  },[])
  return (
    
    
    <div className='singleGallery'>

          <div className="container">
              <div className="row justify-content-evenly">
                  <div className="col-md-10">
                  { 
       
                    dataValue.map(val => (
                            <div className="dataValue mt-2" key={val.id}>
                                <div className="">
                                    <h5 className='text-end '><span className='fw-bold border-bottom border-success'>Dated:</span> {val.event_date}</h5>
                                    <h3 className='my-3'><span className='bg-success text-white rounded p-1'>Event:</span> <span className='fw-bold border-bottom border-success'>{val.event_title}</span></h3>

                                </div>
                                <div className=" mb-5">
                                      <h5 > <span className='bg-success text-white rounded px-3 pb-1'> About this Event</span></h5>
                                        <p className='textIndent pt-2'>{val.event_description}</p>
                                </div>  
                                <div className="text-end my-2">
                                    {val.google_photos !== "" ?  <a className='btn  btn-primary' target="_blank" href={`${val.google_photos}`}>All Photos</a> : null }
                                </div>
                                <div className="singleNewImage text-center">
                                  <img src={`https://www.gmdc.edu.pk/admin-panel/images/${val.image0}`}   style={{width:"100%",height:"",marginBottom:"10px"}} height="700px" />

                                  {val.image1 !== "" ?  <img src={`https://www.gmdc.edu.pk/admin-panel/images/${val.image1}`}  style={{width:"100%",height:"",marginBottom:"10px"}} /> : null }

                                {val.image2 !== "" ?  <img src={`https://www.gmdc.edu.pk/admin-panel/images/${val.image2}`}  style={{width:"100%",height:"",marginBottom:"10px"}} /> : null }

                                {val.image3 !== "" ?  <img src={`https://www.gmdc.edu.pk/admin-panel/images/${val.image3}`}  style={{width:"100%",height:"",marginBottom:"10px"}}  /> : null }

                                {val.image4 !== "" ?  <img src={`https://www.gmdc.edu.pk/admin-panel/images/${val.image4}`}  style={{width:"100%",height:"",marginBottom:"10px"}}  /> : null }

                                
                                  
                                </div>
                                

                                {val.event_video !== "" ? <div className="eventVideo my-5"> <iframe className='heightIntro' style={{width:"100%",margin:"auto", height:"400px"}} src={`https://www.youtube.com/embed/${val.event_video}`} title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></div> : null }
                                
                                <div>   
                                </div>
                            </div>                        
                            )
                    )

                }
                  </div>
              </div>
          </div>
      </div>
  )
}

export default Singlegallery