import React from "react";
import "./Glimpse.css";
import Pageheader from "../Components/Pageheader";
const Glimpse = () => {
    return(
        <>
            <Pageheader title="College Glimpse"/>
            <div className="container">
                <div className="glimpsemain my-5">
                    <div className="row justify-content-evenly py-3 my-2">
                        <div className="col-11 col-md-5 ">
                            <div className="imageGlimpse p-3">
                                <img src="./Images/collegegate.JPG" className="w-100" alt="logoImages" />
                            </div>       
                        </div>
                        <div className="col-11 col-md-7">
                            <div className="glimpseTExt">
                                <p>Govt. Municipal Degree College, Faisalabad formerly called the Khalsa College Lyallpur, is one of the most prestigious and historical educational institution of the Central Punjab. it is a creation of Singh Sabha Movement with the purpose of providing quality education and awareness to the Punjabis without socio-religious discrimination. its historical significance dates back to as earlier as 1908.</p>

                                <p>The foundation of Faisalabad (formerly called Lyallpur) as a new city was laid in 1897 and a single Govt. High School was opened to impart early was not sufficient to meet its continuously increasing educational demand. So, in 1908 some education loving and generous people of the city, most of whom were Sikhs.</p>
                                
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-evenly py-3 ">
                        <div className="col-11 col-md-7">
                            <div className="glimpseTExt">
                            <p>
                                founded this institution as a high school and named it as KHALSA High School Lyallpur. The piece of land for this institution was a gift from Sardar Jawand Singh of Chak No.213 and its foundation stone was laid down by Sant Bhai Attar Singh Gurmkh Piyara on December 25th, 1908. its building was a master-piece of Indian and British architecture.
                                </p>

                                <p>
                                In 1926, the Khalsa High School was upgraded to a college. The inhabitants of Lyallpur and surrounding areas quenched their thirst for learning from this institution till the partition of Sub-Continent in 1947. During partition, its patrons migrated to Jalandhar in India and established another collegethere in commemoration of thier emotional association with this college and namedit as Lyallpur Khalsa College, Jalandhar. Both of the collegeshare many of thier traditions and enjoy very strong bilateral relations on historical, intellectual and emotional grounds.
                                </p>
                            </div>
                        </div>
                        <div className="col-11 col-md-5 ">
                            <div className="imageGlimpse p-3">
                                <img src="./Images/glimps3.JPG" className=" w-100" alt="logoImages" />
                            </div>       
                        </div>
                    </div>

                    <div className="row justify-content-evenly py-3 ">
                        <div className="col-11 col-md-5 ">
                            <div className="imageGlimpse p-3">
                                <img src="./Images/glimps2.JPG" className="ms-1 w-100" alt="logoImages" />
                            </div>       
                        </div>
                        <div className="col-11 col-md-7">
                            <div className="glimpseTExt">
                                
                                <p>
                                After partition, the educational activities of this college were suspended and its campus was converted into a refugee camp. But an "Anjman" tried to restore its educational activities to some extent in 1958. The Municipal Committee Lyallpur restarted it as "Intermediate Khalsa College, Lyallpur." Late on, it was upgraded to degree level in 1965. Subsequently, on July 1st, 1973, it was provincialized and named as Govt. Municipal Degree College.
                                </p>

                                <p>
                                Govt. Municipal Degree College, Faisalabad has the honour of retaining its prestigious and glorious postion through many ebbs and tides of history. Presently it is imparting education in five disciplines: Humanities, Social Sciences. Natural Sciences, Commerce and Computer Sciences. Offering such a vast variety arising from the combination of tradition and mondernity, the college is dedicated to produce open minded, professionally equipped and spiritually grounded students with an abilitiy to withstand the challenges of fast and competitive world and these aims and objectives are endorsed by the Mission Statement of the college.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Glimpse;