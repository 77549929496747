import React, { Component } from 'react'
import Subheading from '../Components/Subheading'
import Pageheader from '../Components/Pageheader';

export default class Fee extends Component {
  render() {
    return (
      <div className='feestructure'>
          <Pageheader title="Fee & Fine Structure"/>
          <div className="container">
              <div className="row my-5 justify-content-evenly">
                  <div className="col-md-12">
                      <div className="innerFee">
                          {/* fee structure  */}
                          <Subheading title="Fee Payment / Fine"/>
                          <ul className='mx-3 my-3'>
                              <li>The fee/other payments/ dues are to be paid as scheduled by the college authorities from time to time.</li>
                              <li>Payment after the due date will be redived with fine.</li>
                              <li>After the ecpiry of the extended scheduled program, the names are struck off.</li>
                              <li>In such cases, the re-admission depends upon the permission of the Principal.</li>
                              <li>Absence from the college, even in cases of long leave, cannot be an excuse for non payment of the fee/ dues.</li>
                              <li>The Library security deposit will lapse if the student fails to retrieve it within two years after his college tenure.</li>
                          </ul>

                          {/* Fine Concession */}
                          <Subheading title="Fine Concession"/>
                          <ul className='mx-3 my-3'>
                              <li>The Principal has the prerogative to grant tuition fee concession to the deserving students.</li>
                              <li>The fee concession is conditioned with appreciable behaviour and regularity in studies.</li>
                          </ul>

                           {/* Re-admission / Fines */}
                           <Subheading title="Fine Concession"/>
                          <ul className='mx-3 my-3'>
                              <li>Fine worth five (05) rupees and ten (10) rupees is charged for one period absentee and full day absentee respectively.</li>
                              <li>6-dat continuous absence or 10-day continual absence from the college during one month will lead to the removal of student from college rolls/ registration.</li>
                              <li>The student can be re-admission to college only after the payment of Rs. 100 as fine but only once during two-year session.</li>
                              <li>Father / guardian will also have to appear before the committee at the time of the student's re-admission.</li>
                              <li>The student can get re-admission only within 15 days of his being struck off.</li>
                              <li>3-month long absence due to prolonged illness again will result into rustication and Rs. 100 as re-admission fee.</li>
                          </ul>

                          {/* Scholarships */}
                          <Subheading title="Scholarships"/>
                          <p className='intent mx-3 my-3'>All the Govt. Universities, Boards, Local-Bodies, Kila Gift Funds, Fuji Foundation scholarships and the other scholarships specified for all Govt. Colleges are granted to the winning/ deserving students.</p>

                          {/* Instructions for scholarship holders / guide*/}
                          <Subheading title="Instructions for scholarship holders / guide"/>
                          <ul className='mx-3 my-3'>
                              <li>The scholarship holders must observe that:</li>
                              <li>The college fee is paid within the due date, non-payment of fee will cause withdrawal of his right to get scholarship. Once the privilege is withdrawn, it cannot be re-instated.</li>
                              <li>Failure in the annual examination again eliminates student's right to avail scholarship.</li>
                              <li>There will be a proportionate deduction on scholarship if the student absent himself from college.</li>
                              <li>The scholarship holder is not entitled to any other fund (Fee concession, Welfare fund, Kila-Gift fund etc.).</li>
                              
                          </ul>

                            {/* College Card*/}
                            <Subheading title="College Card"/>
                          <p className='intent mx-3 my-3'>The college identity card is issued to every student. In case of the loss of identity card the student is issued a duplicate one for Rs. 100. Every student is required to keep it with himself during the college hours; otherwise disciplinary action may be taken against him.</p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    )
  }
}
