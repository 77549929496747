import React from 'react'
import "./Teacherprofile.css";


const Teacherprofile = (props) => {
  return (
    <div className=" col-lg-4 col-sm-5 col-10 my-3 " >
      <div className="cardProfile ">
        <div className="card teacherProfile bg-light" style={{height:"450px"}}  >
            <img src={`https://www.gmdc.edu.pk/admin-panel/images/${props.timg}`} className="card-img-top teacherProfileImage" alt="..."  />
            <div className="card-body teacherCardBody">
                <h4>{props.head}</h4>
                <h6 className="card-title"><span >Name:</span> {props.name}</h6><hr />
                <h6>Desig. {props.desi}</h6><hr />
                <h6>Edu: {props.edu}</h6><hr />
                <h6>Depart. {props.dep}</h6>
            </div>
            {props.tag ==  1 ? <div className='tag bg-danger'>HOD</div> : null}
            
        </div>
      </div>
    </div>
)
}

export default Teacherprofile