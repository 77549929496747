
import React, { useEffect } from 'react'
// import "./.css";
import { useState } from 'react';
import axios from "axios";
import Teacherprofile from '../Components/Teacherprofile';

const AllTeacher = (props) => {
    const [data,setData ] = useState([])
    // const para = useParams();
  
  useEffect(()=>{
    axios.get(`https://www.gmdc.edu.pk/admin-panel/department_teaching_staff_api.php`)
    .then(response => {
      const ndata = response.data;
      var fdata = ndata.filter(vale =>  
        vale.t_department === props.d_name
      )
      console.log(ndata)
      setData(fdata)
    }) 
    .catch(error => {
      console.log(error)
    })  
  },[])
  return(
          <>
            {
                data.length > 0 ?
                data.map((item,index) => 
                <Teacherprofile name = {item.t_name} tag= {item.status} desi={item.t_designation} edu={item.t_education} dep = {item.t_department} timg={item.t_image} key={index} />
                )
                : <div className="alert alert-success">No Teacher in this department</div>
            }
          </>
  )
}

export default AllTeacher;

