import React, { Component } from 'react'
import Pageheader from '../Components/Pageheader';
import Subheading from '../Components/Subheading';
import "./Parking.css";

export default class Parking extends Component {
  render() {
    return (
        <>
            <div className='Parkingcollege'>
                <Pageheader title="College Parking"/>
                <div className="container">
                    <div className="row my-5 justify-content-evenly">
                        <div className="col-md-12">
                            <div className="innerParking">
                                <Subheading title="The Parking Rules"/>  
                                <div className="innerParking">
                                    <p className='my-4 mx-4'><strong>These are the following Parking Rules:</strong></p>
                                    <ul className='mx-3'>
                                        <li>The cycle stand is working on no profit / no loss basis. The government has provided neither a shed for cycle stand nor a peon to keep watch. On 1st July, 1999 a shed was constructed on self-help basis. The college authorities try to manage the situation with the little amount collected from the students. The students must observe the following rules:</li>
                                        <li>The students must get the motorcycle, cycle registered with the college management.</li>
                                        <li>The value of the vehicle will be evaluated as per its condition.</li>
                                        <li>Every student must get his roll number/name written on the cycle/motorcycle.</li>
                                        <li>The cycle/motor cycle must be doble locked.</li>
                                        <li>If a cycle / motorcycle is found without lock, the student will be fined Rs.50/- for cycle and Rs.100/- for motorcycle. The cycle/motorcycle without locks</li>
                                        <li>must be handed over to the man on duty. A metal token embossed with token number and college name etc. is given at the time of parking. The students must keep it secure. In case of the loss of token the cycle stand management probes into the matter. The student is fined Rs.50/-and Rs.100/- for loss of token. No outsider is allowed to enter the cycle Stand enclosure.</li>
                                        <li>The college students accompanied by outsiders will be punished /fined.</li>
                                        <li>The running of cycle-stand is valuntary not obligatory. It can be closed under unavoidable circumstances and the students may lose this facility</li>
                                        <li>The students must Pay due respect to the man on-duty at the cycle-stand.</li>
                                        <li>Disrespect/use of harsh language will be dealt with severely.</li>
                                        <li>The loss of token must be brought to the notice of the man in charge so that it maynot be misused.</li>
                                        <li>A student found guilty of misusing a lost / stolen token will be fined / handed over to the police and rusticated from the college.</li>
                                    </ul>
                                </div>
                                {/* warning */}
                                <div className="waringparking my-3">
                                    <h4 className='text-danger'>Warning :</h4>
                                    <p className='parentmessageparking'>Smoking and possession of mobile phones within college premisess are strictly prohibited under the instructions of the Punjab Higher Education Department. The violation of this warning will be regarded as a serious offence.</p>
                                </div>
                                 {/* IMPORTANT MESSAGE FOR PARENTS */}
                                 <div className="waringparking my-3">
                                    <h4 className='text-danger'>IMPORTANT MESSAGE FOR PARENTS :</h4>
                                    <p className='parentmessageparking'>The buses of urban transport, route number 11, pass by the college gate. The parents can get a concessional bus card from the company office for their sons so that no riot is created between students and the urban transport workers.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      </>

    )
  }
}
