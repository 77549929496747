import React,{useEffect,useState} from "react";
import "./Home.css";
import Carousel from "../Components/Carousel";
import  News  from "../Components/News";
import  Events  from "../Components/Events";
import PrevousPrincipal from "../Components/PrevousPrincipal";
import Subheading from "../Components/Subheading";
import Pageheader from "../Components/Pageheader";
import {Tabtitle} from "../GeneralFunction/GeneralFunction";
import IntroVideo from "../Components/IntroVideo";
import PrincipalMessage from "../Components/PrincipalMessage";
import PersonalityMessage from "../Components/PersonalityMessage";
import EventsImageVideo from "../Components/EventImageVideo";
import DateRangeIcon from '@material-ui/icons/DateRange';

import axios from "axios";
import { Link } from "react-router-dom";

const Home = () => {
    // useState 
    const [news,setNews ] = useState([]);
    const [open,setOpen] = useState({
            modalState: true   
        });
    
    useEffect(() => {
        axios.get("https://www.gmdc.edu.pk/admin-panel/latest_news_api.php")
        .then(res => {
            const data = res.data;
            setNews(data);
        })
        .catch((error) => {
            console.log(error)
        })
    },[])
    // page title
    Tabtitle("Home - GMGC");

    // model box function close
    function handleShow() {
    setOpen({ modalState: !open.modalState });
    }

    return(
        <>
 
        {/* <!-- Modal --> */}
        {
            news.length > 0 ? 
            <>
                <div className={"modal fade " + (open.modalState ? " show d-block" : " d-none")}  id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Note</h5>
                                <button type="button" onClick={handleShow} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="latestnew   my-2" key={news[0].id}>
                                    
                                    <div className="news_titl">
                                        <h5>{news[0].news_title}</h5> <hr />
                                        <div className="nextPagene">
                                            <p className='par'>{news[0].news_description.substring(0, 60)} </p>
                                            <div className='d-flex justify-content-between'>
                                                <Link to={`/morenews/${news[0].id}`} className='newsreadmore'>ReadMore </Link> 
                                                <div>
                                                <DateRangeIcon fontSize='small' />  {news[0].news_date}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" onClick={handleShow} className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>

            : null
        }
        <Carousel  />
        {/* vedio Section  */}
        <div className="videosection">
                <div className="container">
                    <div className="row my-3 justify-content-evenly ">
                        <div className="col-lg-8 col-12">
                            <div className="video_intro">
                            <IntroVideo />
                            </div>
                        </div>
                        <div className="col-lg-4 col-12 ">
                            <div className="marData">
                                <Subheading title="Annoucement"/>
                                    <News />
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        <PersonalityMessage />
        <PrincipalMessage />
         <PrevousPrincipal/>
    
        {/* {Events} */}  
            <div className="">
                <div className="container">
                    <div className="row my-5 justify-content-evenly">
                        <Pageheader title="Latest Events"/>
                        <div className="innerEventContainer">
                            <div className="row justify-content-evenly my-5">
                                <div className="col-lg-8 col-12 ">
                                    <div className="innerItemImages">
                                        <div className="row  justify-content-evenly">
                                            <EventsImageVideo/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-12 ">
                                    <div className="marDataevent">
                                        <Subheading title="Latest Event"/>
                                            <Events />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </>
    )
}

export default Home;

