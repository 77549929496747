import React from 'react';
import { useEffect } from 'react';
import axios from "axios";
import { useState } from 'react';
import { Link } from "react-router-dom";
// import Latestnews from './Latestnews';
import DateRangeIcon from '@material-ui/icons/DateRange';

import "./News.css";


const News = () => {
    const [latestnews,setLatestnews] = useState([])
    useEffect(() => {
        axios.get('https://www.gmdc.edu.pk/admin-panel/latest_news_api.php')
        .then(response => {
            setLatestnews(response.data);
            // console.log(response.data);
        })
        .catch(error => {
            console.log(error)
        })
    },[])
    
  return (
            // <marquee behavior="scroll" direction="up">
            

                <div id="mymaq" className="mar ">
                    <div className="dataAni">
                    { 
                    latestnews.length > 0 ? 
                    latestnews.map(val => 
                        <div className="latestne shadow p-3 rounded my-2" key={val.id}>
                            <div className="datene">
                                <h5></h5>
                            </div>
                            <div className="news_tite">
                                <h5>{val.news_title}</h5><hr />
                                <div className="nextPagenew ">
                                    <p className='para'>{val.news_description.substring(0, 60)} </p> <br/>
                                    <div className='d-flex justify-content-between'>
                                        <Link to={`/morenews/${val.id}`} className='newsreadmore'>ReadMore </Link> 
                                        <div>
                                        <DateRangeIcon fontSize='small' />  {val.news_date}</div>
                                    </div>
                                </div>
                            </div>
                            <div>   
                            </div>
                        </div>                        
                        
                    )
                    : <div className='alert alert-sucess'>Wait For New news</div>
                    }
                    </div>
                </div>
                // </marquee>

  )
}
export default News;