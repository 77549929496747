import React from "react";
import "./Library.css";
import Subheading from '../Components/Subheading';
import Pageheader from "../Components/Pageheader";
const Library = () => {
    return(
        <>
        <Pageheader title="College Library"/>
        <div className="container">
            <div className="row my-5 justify-content-evenly">
                <div className="col-md-12">
                    <div className="libraryRules">
                        <Subheading title="Library Rules" />
                        <div className="innertextrules">
                            <p className="mx-4 my-3"><strong>These are the following rule in library:</strong></p>
                            <ol>
                                <li>The books are issued and returned on prescribed days.</li>
                                <li>Reference books can be consulted honestly at any time with prior permission of Principal.</li>
                                <li>College teachers, bona-fide college students are entitled to get books</li>
                                <li>Library time period.
                                    <ol>
                                        <li>Teachers can get books for up to two months, which can be re-issued afterwards.</li>
                                        <li>Students can get books for 14 days.</li>
                                    </ol>
                                </li>
                                <li>Number of books issued at one time.
                                    <ol>
                                        <li>Teaching staff upto 06 books.</li>
                                        <li>BS/B.A/B.Sc Students upto 01 books.</li>
                                        <li>Intermediate students upto 01 book. 
                                            <p><strong>The books of the following kind can be issued only with the special permission of the Principal:</strong></p>
                                            <ol>
                                                <li>Reference books</li>
                                                <li>Specially preserved books</li>
                                                <li>Fresh/ latest Magazines</li>
                                                <li>Prescribed Books</li>
                                                <li>Dictionaries</li>
                                            </ol>

                                        </li>
                                        <li>Teaching staff upto 06 books.</li>
                                    </ol>
                                </li>
                                <li>Each student is issued a library card at the time of admission A new library card is issued after promotion to the next class. The card (duly signed by the Librarian) must be presented at the time of issuance and return of the books.</li>
                                <li>Library cards are non-transferable.</li>
                                <li>The returned books are not re-issued (to the same person) on the same day.</li>
                                <li>Afine of 1/-rupee per day, per book, will be charged in case of not returning the book on due date.</li>
                                <li>The lost or damaged book/ series of books will have to be replaced with the same new ones or the actual price is to be paid or if the book is not currently available, in the market, a fine will be imposed as per rules.</li>
                                <li>Books issued just before the summer vacation must be returned to the library soon after the vacation or a fine of Rs. 1/-per volume per day will have to be paid.</li>
                                <li>The loss of card must be reported to the librarian. Rs.10/-will be charged for the duplicate card.</li>
                                <li>The students cannot interchange the library books.</li>
                                <li>The college leaving students can get the clearance certificate / NOC after returning the library books along with the card to the librarian.</li>
                                <li>Complete silence must be observed in the library. Disturbance can earn penalty.</li>
                                <li>The library remains closed for physical verification during the month of May every year. All the books must be returned before this period</li>
                                <li>The act of removing a page/picture out of a book is severely dealt with.</li>
                                <li>Use of Mobile phone is prohibited in the library premises.</li>
                            </ol>
                        </div>
                        {/* The Reading Room Guide */}
                        <div className="readingroom mt-5 mx-3">
                            <Subheading title="Reading Room Guide"/>
                            <p className="mx-4 my-3"><strong>These are the following Reading Room Guide:</strong></p>
                            <ol className="mx-4 my-3">
                                <li>No student is allowed to remove the reading material from news stands/tables.</li>
                                <li>The taking away of a magazine, newspaper, digest without issuance to one's name will be fined.</li>
                                <li>Removing a picture/page from a book/magazine is an obnoxious crime and will be punished severely.</li>
                                <li>Marking on the papers/ magazines entails punishment.</li>
                                <li>No marginal note making on library books is allowed.</li>
                                <li>Complete silence must be observed in the reading room otherwise the student can be sent out/fined for violation of rules.</li>
                                <li>The students are required to maintain peace and quietude in the library.</li>
                            </ol>
                        </div>
                        {/* THE RETRIEVAL OF LIBRARY SECURITY*/}
                        <div className="readingroom ">
                            <Subheading title="THE RETRIEVAL OF LIBRARY SECURITY"/>
                            <p className="retriveLibray my-3">After the academic session, the library security is returned only within the prescribed period. After it, the security amount is surrendered to the college. The students will present college card at the time of getting back the security.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
export default Library;