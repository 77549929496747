import React, { Component } from 'react';
import "./Subheading.css";

export default class Subheading extends Component {
    constructor(props){
        super(props);
    }
  render() {
    return (
      <div className='subheading'>
          <h2 className='text-center'>{this.props.title}</h2>
      </div>
    )
  }
}
