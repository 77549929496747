import React from "react";
import "./Nonteaching.css";
import Pageheader from "../Components/Pageheader";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import Non_Teaching_staff from "../Components/Non_Teaching_staff";
const Nonteaching = () => {
    const [files,setFiles] = useState({
        non_teaching_profile: "",
        staff_name: "",
        staff_designation: ""
    });

    useEffect(() => {
        axios.get("https://www.gmdc.edu.pk/admin-panel/nonteaching_staff_api.php")
        .then((response ) => {
            // console.log(response.data)
            setFiles(response.data)
            
        })
        .catch(error => {
            console.log(error)
        })
    },[])
    console.log(files)
    return(
        <div className="nonteaching">
        <Pageheader title="Non Teaching Staff"/>
        <div className="container">
            <div className="row my-5 ">
                {
                    files.length > 0 ? 
                        files.map(item => (
                            <Non_Teaching_staff profile={item.non_teaching_profile} staff_name = {item.staff_name} staff_designation={item.staff_designation} />
                        ))
                    : null
                }
            </div>
        </div>
    </div>
    )
}
export default Nonteaching;