import React, { Component } from 'react';
import Subheading from '../Components/Subheading';
import "./Exam.css";
import Pageheader from '../Components/Pageheader';


export default class Exam extends Component {
  render() {
    return (
      <div className='ExamPage'>
          <Pageheader title="Exam Scheduals"/>
          <div className="container">
              <div className="row my-5">
                  <div className="col-md-12">
                      <div className="examschedules">
                          <Subheading title="Criteria for appearing in the BISE and University Examinations"/>
                          <div className="innerExamSchedules my-3">
                              <p className='mx-3'><strong>Criteria for appearint in exam:</strong></p>
                              <ul className='mx-3 my-3'>
                                  <li>The students getting F grade in the internal examinations are not eligible for appearing in the BISE or University Examinations.</li>
                                  <li>Violation of college rules cancels the student's right to appear in examinations.</li>
                                  <li>The principal is authorized to suspend the admission of any student to appear in the examinations ten(10) days before the commencement of examination.</li>
                                  <li>75% lecture attendance for B.I.S.E. Fsd, Examinations and 60% lecture attendance is required for University examination.</li>
                                  <li>Leave applications are no substitute for lecture shortage, even if the student has to appear for the examination by parts.</li>
                                  <li>Lecture attendance in each of the subjects must be up to the mark.</li>
                                  <li>As for the medical certificate, the student has to observe the following rules in getting leave.</li>
                              </ul>
                              {/* Leave application must bear clearly:- */}
                              <p className='mx-3'><strong>Leave application must bear clearly:-</strong></p>
                              <ol className='mx-3 my-3'>
                                  <li>Student's name, class, roll no, tutorial group no, date, reason for leave, total number of days for which the leave is required.</li>
                                  <li>Submit the leave application to the teacher in charge of the class. The posted application must be addressed to the person in charge (not to the Principal). Leave application for more then four days should bear the father's / guardian's signature.</li>
                                  <li>The teacher in charge of the class is authorized to grantr four-day leave except in case the leave i required during the house-tests. The application of leave for more then four days must be forwaded to the Principal with the recommendation of the teacher in charge.</li>
                              </ol>
                              {/* The leave application will not be accepted if:- */}
                              <p className='mx-3'><strong>The leave application will not be accepted if:--</strong></p>
                              <ul className='m-3'>
                                  <li>The student fails to submit the application within three days after his returning to the college.</li>
                                  <li>The application does not bear the father's / guardian's signature.</li>
                                  <li>Failing it the student will be fined Rs. 10 per day. There will be no fine if the medical certificate reaches the college office even late. Application along with medical certificate may be received and accepted even a week afterwards.</li>
                                  <li>Leave for more then seven days without medical certificate will be granted only on the recommendations of the senior tutor.</li>
                              </ul>
                          </div>
                         
                          {/* GRADES */}
                          <Subheading title="GRADES"/>
                          <p className='mx-3 intent'><strong>The F.A. / F.Sc. internal grages awarded on the basis of house tests are mentioned in the board of intermediate and secondary education certificates. The set formula for gradation is:</strong></p>
                          <div className="row justify-content-evenly my-3">
                              <div className="col-md-9">
                              <table className="table table-striped table-hover">
                                <thead>
                                    <tr>
                                    <th scope="col">Sr.#</th>
                                    <th scope="col">Grade</th>
                                    <th scope="col">Number %</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                    <th scope="row">1</th>
                                    <td>A+</td>
                                    <td>80% and above</td>
                                    </tr>
                                    <tr>
                                    <th scope="row">2</th>
                                    <td>A</td>
                                    <td>70% - 79%</td>
                                    </tr>
                                    <tr>
                                    <th scope="row">3</th>
                                    <td>B</td>
                                    <td>60% - 69%</td>
                                    </tr>
                                    <tr>
                                    <th scope="row">4</th>
                                    <td>C</td>
                                    <td>50% - 59%</td>
                                    </tr>
                                    <tr>
                                    <th scope="row">5</th>
                                    <td>D</td>
                                    <td>40% - 49%</td>
                                    </tr>
                                    <tr>
                                    <th scope="row">6</th>
                                    <td>E</td>
                                    <td>33% - 39%</td>
                                    </tr>
                                    <tr>
                                    <th scope="row">7</th>
                                    <td>F</td>
                                    <td>Below the 33%</td>
                                    </tr>
                                </tbody>
                              </table>
                              </div>
                          </div>

                          {/* Note: F grade students are not eligible to appear in B. I. S. E. Examination. */}
                          <Subheading title="Note: F grade students are not eligible to appear in B. I. S. E. Examination."/>
                          <p className='mx-3 intent'>The study progress report is sent to the parents/ guardians, if the Principal finds the performance of a student unsatisfactory.The student can be compelled to leave the institution at any time during his academic session. Any student found guilty of using unfair means in the examination can be rusticated from the college or punished in other suitable terms.</p>

                      </div>
                  </div>
              </div>
          </div>
      </div>
    )
  }
}
