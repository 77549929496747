import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';

import { ToastContainer, toast } from 'react-toastify';
import "./Contact.css";
import Pageheader from "../Components/Pageheader";
import {Tabtitle}  from "../GeneralFunction/GeneralFunction";
const Contact = () => {
    Tabtitle("Contact Us - GMGC")
    const [inputs, setInputs] = useState({
        names : "",
        email: "",
        message: "",
        
    });
    const [formErrors,setFormErrors] = useState({});
    const [isSubmit, setIsSubmit]  = useState(false)

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}))
  }


  const handleSubmit = (event) => {
    event.preventDefault();
    setFormErrors(validate(inputs))
    setIsSubmit(true)
        if(inputs.names !== '' && inputs.email !== "" &&  inputs.message !== ""){
            axios.post("https://www.gmdc.edu.pk/admin-panel/contact_us_api.php",JSON.stringify(inputs))
            setInputs({
                names : "",
                email: "",
                message: "",
            })
        }
    
  }



 useEffect(() => {
    // console.log(formErrors)
    if(Object.keys(formErrors).length === 0 && isSubmit){
        toast("Data Insert Successfully!")
           
    }
 },[formErrors])


    const notify = () =>  {
        if(Object.keys(formErrors).length === 0 && isSubmit){
            // toast("Data Insert Successfully!")
        }
        else{
            // toast("Fill This Form Correctly")
        }
    }


  const validate = (values) => {
    const error={}
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if(!values.names)
    {
        error.names = "Name is Required!";
    }
    if(!values.email)
    {
        error.email = "Email is Required!";
    }
    if(!values.message)
    {
        error.message = "Message is Required!";
    }
    return error;
  }



     
    return(
        <>
        <Pageheader title="Contact Us"/>
        <div className="container">
            <div className="row my-5">
                <div className="col-md-6 my-3">
                    <form onSubmit={handleSubmit}>
                        <h3>How We Can Help You?</h3>
                            <div className="form-group ">
                                <label htmlFor="name" className='form-label'>Person Name:</label>
                                <input type="text"  name="names" className='form-control' onChange={handleChange}  value={inputs.names || ""}  placeholder='Name' id="name" />
                            </div>
                            <p className='text-danger'>{formErrors.names}</p>

                            <div className="form-group ">
                                <label htmlFor="email" className='form-label'>Email:</label>
                                <input type="email"  name="email" className='form-control' onChange={handleChange}  value={inputs.email || ""}  placeholder='Email' id="email" />
                            </div>
                            <p className='text-danger'>{formErrors.email}</p>
                            <div className="form-group ">
                                <label htmlFor="message" className='form-label'>Message:</label>
                                <textarea name="message" className='form-control w-100' onChange={handleChange}  value={inputs.message || ""}  placeholder='Message' id="Message"  rows="7"></textarea>
                            </div>
                            <p className='text-danger'>{formErrors.message}</p>


                        <button type="submit" className="btn btn-primary" onClick={notify} >Submit</button>
                    </form>
                </div>
                <div className="col-md-6 my-3">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d27240.719703727915!2d73.04343938827517!3d31.411647361191402!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x392242a52bcd800b%3A0x1824e0735664b169!2sGovernment%20Municiple%20Corporation%20Despensary%20Faisalabad!5e0!3m2!1sen!2s!4v1655018954180!5m2!1sen!2s" style={{border:'0',width:"100%",height:"100%"}}  loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe> 
                 </div>
            </div>
        </div>        
                <ToastContainer position="top-center"/>
        </>
    )
}
export default Contact;