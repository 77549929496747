import React,{useState,useEffect} from 'react'
import Subheading from '../Components/Subheading'
import Pageheader from '../Components/Pageheader';
import axios from "axios";

import "./Timetable.css";

const Timetable = () => {

  const [data,setData] = useState([]);
  useEffect(
    () => {
      axios.get("https://www.gmdc.edu.pk/admin-panel/timetable_api.php")
      .then(response => {
        // console.log(response.data)
        setData(response.data)
        console.log(response.data)
      })
      .catch(error => {
        console.log(error)
      })
    }
    ,[])
  return (
    <div className='Timetable'>
        {/* <Pageheader title=" College Time Table"/> */}
        <div className="container">
            <div className="row justify-content-evenly my-3">
                
                <div className="col-md-12">
                    <div className="innertimetable">
                        <Subheading title="Time Table"/>
                    </div>
                </div>

                <div className="col-md-12 mt-4"  >
                    <div className="table-responsive">
                        <table className='table table-striped table-hovered '>
                        <thead className='thead-dark '>
                          <tr>
                            <th>Title</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                    
                          {
                            data.length > 0 ?
                            data.map(val => (
                              
                                    <tr key={val.id}>
                                      <td>{val.time_table_title}</td>
                                      <td>
                                      <a target="_blank" href={`https://www.gmdc.edu.pk/admin-panel/images/${val.file_name}`} download>
                                        Download
                                      </a>

                                      </td>
                                    </tr>
                                  
                            ))
                            :  <div className="alert alert-sucess">No Found any data</div>
                          }
                    
                        </tbody>
                      </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Timetable
