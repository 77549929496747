import axios from 'axios';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link } from "react-router-dom";
import "./Events.css";
import DateRangeIcon from '@material-ui/icons/DateRange';


const Events = () => {
        const [latestevent,setLatestEvent] = useState([])
        useEffect(() => {
            axios.get("https://www.gmdc.edu.pk/admin-panel/latest_event_api.php")
            .then((response ) => {
                setLatestEvent(response.data)
                // console.log(response.data)
            })
            .catch((error) => {
                console.log(error)
            })
        },[])
  return (
                <>
                    <div id="eventLatest">
                        <div className="eventLatest">
                        {
                       latestevent.length > 0 ?
                       latestevent.map( (value,index) => 
                       <div className="latesteven " key={index}>
                           <div className="event_titl shadow rounded p-3">
                               <h5>{value.event_title.substring(0, 30)}</h5> <hr />
                               <div className="nextPageevet ">
                                <p className='para'>{value.event_description.substring(0, 60)} </p>
                                    <div className='d-flex justify-content-between'>
                                        <Link to={`/singlegallery/${value.id}`} className='eventreadmore'>ReadMore</Link><div>
                                    <DateRangeIcon fontSize='small' />
                                    {value.event_date}
                                    </div>
                                    
                                    </div>
                               </div>
                           </div>
                           <div>
                               
                           </div>
                       </div>
                   ) :
                   <div className='alert alert-success'>Wait For New Events</div>
                    }
                        </div>
                        
                    </div>
                </>
  )
}
export default Events;