import axios from 'axios';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link } from "react-router-dom";
import "./Gallery.css";

const EventsImageVideo = () => {
        const [latestevent,setLatestEvent] = useState([])
        useEffect(() => {
            axios.get("https://www.gmdc.edu.pk/admin-panel/latest_event_api.php")
            .then((response ) => {
                setLatestEvent(response.data)
                // console.log(response.data)
            })
            .catch((error) => {
                console.log(error)
            })
        },[])
  return (
                <div className="container">
                    <div className='row justify-content-evenly'>
                    {
                        
                        latestevent.length > 0 ? 
                        latestevent.map( (value,index) => (
                            <div className="col-lg-4" key={index}>
                                <Link to={`/singlegallery/${value.id}`}>
                                <div className="gallaryImage latestevent">
                                <img src={`https://www.gmdc.edu.pk/admin-panel/images/${value.image0}`} alt="" />
                                <div className="galleryImageText">
                                    <h1>{value.event_date}</h1>
                                </div>
                                </div>
                                </Link>
                            </div>
                        )) : 
                        <div className="alert alert-success">No Any Gallery Stored </div>
                    }
                </div>
                </div>
  )
}
export default EventsImageVideo;