import React from "react";
import "./Department.css";
import Pageheader from "../Components/Pageheader";
import Departmentcard from "../Components/Departmentcard";
// import { Link } from "react-router-dom";
import {Tabtitle}  from "../GeneralFunction/GeneralFunction"    
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
const Department = () => {
    Tabtitle("Departments - GMGC");
    const [data,setData] = useState({});
    useEffect(()=> {
        axios.get("https://www.gmdc.edu.pk/admin-panel/department_api.php")
        .then(response => {
            // console.log(response.data)
            setData(response.data)
        })
        .catch(error => {
            console.log(error)
        })
    },[])
    // console.log(data)

    return(
        <div className="maindepartmentpage">

        <Pageheader title="Departments"/>
        
        <div className="container">
            <div className="row my-5 justify-content-evenly">
            {
                data.length > 0 ?
                data.map(val => (
                    <Departmentcard title={val.d_name} key={val.d_id} id={val.d_id} d_logo={val.d_logo} />
                ))
                : <div className="alert alert-success">No Any Department Stored </div>
            }
            
            </div>
        </div>
        </div>
    )
}
export default Department;