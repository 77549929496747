import axios from 'axios';
import React, { useEffect, useState } from 'react';
import "./Carousel.css";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const Carousel = () => {
  
    const [header,setHeader] = useState([]); 
        useEffect( () => {
        axios.get('https://www.gmdc.edu.pk/admin-panel/header_slider_api.php')
            .then(response => {
                 setHeader(response.data)
            })
            .catch( error => {
                console.log(error);
            }
            )
        }

    ,[])

  return (


              <div className="container px-0">
                  <div className="row px-0 mx-0">
                  <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
                      <div className="carousel-inner">
                        {
                          header.length > 0 ? 
                            header.map(( value,index) => (
                              <div className={index === 0 ?  "carousel-item active" : "carousel-item"} key={value.id}>
                                <img src={`https://www.gmdc.edu.pk/admin-panel/images/${value.slider_image}`} className="d-block w-100 carouse-height" alt="..."/>
                                <div className="carousel-caption d-none d-md-block">
                                  <h3>{value.slider_text}</h3>
                                </div>
                              </div>
                            ))
                          : null
                        }
                        
                      </div>
                      <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                      </button>
                      <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                      </button>
                    </div>
                  </div>
            </div>

  )
}
export default Carousel;
