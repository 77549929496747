import React from "react";
import "./Pageheader.css"
const Pageheader = (props) => {
    return(
        <>
            <div className="headingAll my-0 ">
                <h1 className="text-center text-white">{props.title}</h1>
            </div>
        </>
    )
}
export default Pageheader;