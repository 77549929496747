import React from "react";
import {Link} from "react-router-dom";
import FacebookIcon from '@material-ui/icons/Facebook';
import EmailIcon from '@material-ui/icons/Email';
import YoutubeIcon from '@material-ui/icons/YouTube';
import PhoneIcon from '@material-ui/icons/Phone';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
// import footerlogo from "../Images/logo2.png";
import "./Footer.css";
const Footer = () => {
    return(
        <>
            <div className="main_div_footer">
                <div className="container">
                    <div className="row py-5 justify-content-evenly">
                        <div className="col-lg-4 col-sm-10 pb-3 col-9">
                            <div className="footer_logo">
                                <Link to="/" className="text-white">
                                    <img src="../Images/logo2.png" alt="footerlogo" className="footerimg"/>
                                    <div className="logotext ">
                                        <h5>Government Municipal <br/> Graduate College</h5>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-2 col-sm-10 pb-3    col-10">
                            <div className="footer_pages text-start">
                                <h5>Many More</h5>
                                <ul className="footer_links ">
                                
                                <li className="nav-item">
                                    <Link className="nav-link" to="/fee">Fee & Fine </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/exam">Exam Scheduals</Link>
                                </li>
                                
                                <li className="nav-item">
                                    <Link className="nav-link" to="/parking">Parking</Link>
                                </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-2 col-sm-10 pb-3   col-10">
                            <div className="socialLinks">
                                <h5>Quick Access</h5>
                                <ul className="footer_links">
                                <li className="nav-item">
                                    <Link className="nav-link" to="/download">Download</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/timetable">Time Table</Link>
                                </li>
                                </ul>
                                {/* <h5 className="">Quick Access</h5> */}
                                
                            </div>
                        </div>

                        <div className="col-lg-3 col-sm-10 pb-3  col-10">
                            <div className="socialLinks">
                                <h5>Social Links</h5>
                                <ul className="footer_social_links my-4 px-2">
                                    <li className="nav-item">
                                        <a href="https://www.facebook.com/gmdc.cs" target="_blank" className="facebook" ><FacebookIcon /></a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="https://www.youtube.com/" target="_blank" className="youtube" ><YoutubeIcon /></a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="https://twitter.com/" target="_blank" className="twitter" ><TwitterIcon /></a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="https://www.instagram.com/" target="_blank" className="instagram" ><InstagramIcon /></a>
                                    </li>
                                </ul>
                                <ul className="footer_quick_links ">
                                    <li className="my-2">
                                        <a href="mailto:info@gmdc.edu.pk" target="_blank"><EmailIcon /><span className="mx-3 ">info@gmdc.edu.pk</span></a>
                                    </li>
                                    <li className="my-2">
                                        <a href="tel:(041) 9220283" target="_blank"><PhoneIcon /><span className="mx-3 ">(041) 9220283</span></a>
                                    </li>
                                    <li><p>Govt.Municipal Degree College Faisalabad Jaranwala Road, Faisalabad</p></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copyrightdiv">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="copyright_des">
                                <p className="text-center">
                                    Copyright &copy; 2022 AllRights Reserved. <Link to="/" className="footerWeb">gmdc.edu.pk</Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Footer;