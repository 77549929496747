import "./Login.css";
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';

import { ToastContainer, toast } from 'react-toastify';
import {Tabtitle}  from "../GeneralFunction/GeneralFunction";

function LogIn() {
    Tabtitle("Login - GMGC")
    const [inputs, setInputs] = useState({
        email : "",
        password: "",        
    });
    const [formErrors,setFormErrors] = useState({});
    const [isSubmit, setIsSubmit]  = useState(false)

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}))
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormErrors(validate(inputs))
    setIsSubmit(true)
        axios.post("https://www.gmdc.edu.pk/admin-panel/login_api.php",JSON.stringify(inputs))
    .then(response => {
        console.log(response.data)
        setInputs("");
        if(response.data){
            toast("Login Successfully!")
        }
    }) 
    .catch(error => {
        console.log(error)
        toast("Error! Fill this Form Correctly")
    }) 
    
  }

 useEffect(() => {
    console.log(formErrors)
    if(Object.keys(formErrors).length === 0 && isSubmit){
        
           
    }
    else{

    }
 },[formErrors])


    const notify = () =>  {
        if(Object.keys(formErrors).length === 0 && isSubmit){
            // toast("Data Insert Successfully!")
        }
        else{
            // toast("Fill This Form Correctly")
        }
    }


  const validate = (values) => {
    const error={}
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if(!values.email)
    {
        error.email = "email is Required!";
    }
    if(!values.password)
    {
        error.password = "password is Required!";
    }
    return error;
  }
  return (
    <React.Fragment>
        <div className="container-fluid mx-0 px-0" id='signinback'>
            <div className="row justify-content-evenly py-5 px-0 mx-0 imagefrontsignin">
                <div className="col-md-5">
                    <form className=' text-white p-5' id='formsignin' onSubmit={handleSubmit}>
                        <h1 className='text-center text-white'>Sign in</h1>
                        <div className="form-group ">
                                <label htmlFor="email" className='form-label'>Email:</label>
                                <input type="email"  name="email" className='form-control' onChange={handleChange}  value={inputs.email || ""}  placeholder='email' id="email" />
                            </div>
                            <p className='text-danger'>{formErrors.username}</p>

                            <div className="form-group ">
                                <label htmlFor="password" className='form-label'>Password:</label>
                                <input type="password"  name="password" className='form-control' onChange={handleChange}  value={inputs.password || ""}  placeholder='password' id="email" />
                            </div>
                            <p className='text-danger'>{formErrors.password}</p>
                            <button className="btn btn-danger px-4 mt-3" type="submit" onClick={notify} >Login</button>
                    </form>
                </div>
            </div>
        </div>
        <ToastContainer  position="top-center"/>
    </React.Fragment>
  )
}

export default LogIn