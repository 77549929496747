import React, { useEffect } from 'react'
import "./Singledepartment.css";
import {useParams} from "react-router-dom";
import { useState } from 'react';
import axios from "axios"
import Teacherprofile from '../Components/Teacherprofile';
import AllTeacher from '../Components/AllTeacher';

const Singledepartment = () => {
  const [data,setData ] = useState([])
  const para = useParams();

useEffect(()=>{
  axios.get(`https://www.gmdc.edu.pk/admin-panel/department_api.php`)
  .then(response => {
    const ndata = response.data;
    var fdata = ndata.filter(vale =>  
      vale.d_id === para.id  
    )
    // console.log(fdata)
    setData(fdata)
  }) 
  .catch(error => {
    console.log(error)
  })  
},[])
console.log(data)
  return(
      <>
          <div className="container-fluid px-0 mx-0">

              <div className="row justify-content-evenly mx-0 px-0 singleDepartment" >
                {
                  data.map(item => (
                   <>
                     <div className="col-md-12 mx-0 px-0" key={item.id}>
                        <div className="bgImage">
                          <img src={`https://www.gmdc.edu.pk/admin-panel/images/bg.jpeg`} alt='pass' style={{width:"100%",height:"100%"}} className='constentImage'/>
                        </div>
                        <div className="bannerText">
                          <h2 className='text-center text-white'>Department <br/> Of <br/> {item.d_name} <br/> </h2>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="AllTeacher">
                        </div>
                      </div>
                   </>
                  ))
                }
            </div>
          </div>
            <div className="container">
              <div className="row justify-content-evenly my-5 mx-0 px-0">
                {
                  data.map(item => (
                        <AllTeacher d_name={item.d_name} />
                  ))
                }
                </div>
            </div>
      </>
  )
}

export default Singledepartment
