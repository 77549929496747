import React, { useState } from 'react'
import Pageheader from './Pageheader'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./PreviousPrincipal.css";
import { useEffect } from 'react';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import axios from 'axios';

const NextArrow = (props) => {
    const {onClick} = props
    return(
      <div className="control-btn">
        <button className="next" onClick={onClick}>
        <ArrowForwardIcon />
        </button>
      </div>
    )
  }
  const PrevArrow = (props) => {
    const {onClick} = props
    return(
      <div className="control-btn ">
        <button className="prev" onClick={onClick}>
          <KeyboardBackspaceIcon />
        </button>
      </div>
    )
  }
const PrevousPrincipal = () =>{
  const [prevprincipal,setPrevPrincipal] = useState([]);
  useEffect(() => {
    axios.get("https://www.gmdc.edu.pk/admin-panel/previous_principal_api.php")
    .then(response => {
      setPrevPrincipal(response.data)
      // console.log(response.data)
    })
    .catch(error => {
      console.log(error);
    })
  },[])
        
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    nextArrow:<NextArrow/>,
    prevArrow: <PrevArrow/>,
    pauseOnHover: true,
    responsive: [{
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        centerMode: false
      }
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false
      }
    },
    ,
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: false
      }
    }]
        
      };
  return (

    <div className="container">
        <div className="row justify-content-evenly my-3">
            <div className="col-12">
                <Pageheader title="Previous Principals" />
            </div>
        </div>
        <div className=" row  justify-content-evenly px-3 ">
            <Slider {...settings}>
                
            {
              // console.log(prevprincipal)
              prevprincipal.length >=1 ? 
              prevprincipal.map(value => (
                <div className="col-6 col-lg-4 col-sm-4  px-1 " id='colHeight'>
                    <div className="mainPrincipal  ms-3 " >
                     <div className="prevPrincipalImage">
                         <img src={`https://www.gmdc.edu.pk/admin-panel/images/${value.pp_image}`} alt="Image" className='prevImage' />
                     </div>
                     <div className="detainPrincipal text-center h-100">
                         <h6 className=''><b>{value.pp_name}</b></h6><hr className='w-75 m-auto' />
                         <p>
                            <b>DOJ: </b> 
                            {value.pp_joining_date}
                          </p>
                         <hr className='w-75 m-auto' />  
                         <p>
                            <b>DOR: </b>
                            {value.pp_leaving_date}
                          </p>
                     </div>
                    </div>
                    </div>
              ))
              : null
            }
        </Slider>

        </div>
    </div>
  )
}

export default PrevousPrincipal