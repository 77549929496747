import React from "react";
import { Route, Routes } from "react-router-dom";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Home from "./Pages/Home";
import Contact from "./Pages/Contact";
import Gallery from "./Pages/Gallery";
import Department from "./Pages/Department";
import Programme from "./Pages/Programme";
import Nonteaching from "./Pages/Nonteaching";
import Mission from "./Pages/Mission";
import Rule from "./Pages/Rule";
import Glimpse from "./Pages/Glimpse";
import Parking from "./Pages/Parking";
import Library from "./Pages/Library";
import  Morenews  from "./Pages/Morenews";
import "./App.css";
import Exam from "./Pages/Exam";
import Fee from "./Pages/Fee";
import Timetable from "./Pages/Timetable";
import Events from "./Components/Events"
import  Download  from "./Pages/Download";
import Singledepartment from "./Pages/Singledepartment";
import Singlegallery from "./Pages/Singlegallery";
import LogIn from "./Components/LogIn";
import NewsPage from "./Pages/NewsPage";
import ViewForm from "./Pages/ViewForm";
import Developer from "./Pages/Developer";


const App = () => {
  return(
    <>
      <Header />
      <Routes>
        <Route index path="/" element={<Home/>} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="/gallery"  element={<Gallery/>}/>
        <Route path="/news"  element={<NewsPage/>}/>
        <Route path="/department" element={<Department/>} />
        <Route path="/programme" element={<Programme/>} />
        <Route path="/nonteaching" element={<Nonteaching/>} />
        <Route path="/mission" element={<Mission/>} />
        <Route path="/glimpse" element={<Glimpse/>} />
        <Route path="/rule" element={<Rule/>} />
        <Route path="/library" element={<Library/>} />
        <Route path="/morenews/:id" element={<Morenews/>}/>
        <Route path="/parking" element={<Parking/>} />
        <Route path="/exam" element={<Exam/>} />
        <Route path="/fee" element={<Fee/>} />
        <Route path="/timetable" element={<Timetable/>} />
        <Route path="/download" element={<Download/>} />
        <Route path="/moreevent/:id" element={<Events/>} />
        <Route path="/singledepartment/:id" element={<Singledepartment/>} />
        <Route path="/singlegallery/:id" element={<Singlegallery/>} />
        <Route path="/login" element={<LogIn/>}/>
        <Route path="/developer" element={<Developer/>} />
        <Route path="/*" element={<Home/>} />
        
      </Routes>
      <Footer />
    </>
  )
};
export default App;
