import Pageheader from '../Components/Pageheader';
import Subheading from '../Components/Subheading'
import React from 'react'
import "./Download.css";
import { useState,useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const Download = () => {
  const [data,setData] = useState([]);
  useEffect(
    () => {
      axios.get("https://www.gmdc.edu.pk/admin-panel/download_form_api.php")
      .then(response => {
        // console.log(response.data)
        setData(response.data)
      })
      .catch(error => {
        console.log(error)
      })
    }
    ,[])
  return (
    <div className='Download'>
      {/* page name  */}
        {/* <Pageheader title="Download" /> */}
        <div className="container">
            <div className="row justify-content-evenly my-3">

                <div className="col-md-12">
                    <div className="innerDownload">
                        <Subheading title="Download Forms"/>
                    </div>
                </div>
                <div className="col-md-12 mt-4"  >
                    <div className="table-responsive">
                        <table className='table table-striped table-hovered '>
                        <thead className='thead-dark '>
                          <tr>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                    
                          {
                            data.length > 0 ?
                            data.map(val => (
                              
                                    <tr key={val.id}>
                                      <td>{val.file_title}</td>
                                      <td>{val.file_description}</td>
                                      <td>
                                      <a target="_blank" href={`https://www.gmdc.edu.pk/admin-panel/images/${val.file_name}`} download>
                                        Download
                                      </a>

                                      </td>
                                    </tr>
                                  
                            ))
                            :  <div className="alert alert-sucess">No Found any data</div>
                          }
                    
                        </tbody>
                      </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Download