import React from 'react'

const Non_Teaching_staff = (props) => {
  return (
    <div className=" col-lg-4 col-sm-5 col-10 my-3 " >
      <div className="cardProfile ">
        <div className="card teacherProfile bg-light" style={{height:"450px"}}  >
            <img src={`https://www.gmdc.edu.pk/admin-panel/images/${props.profile}`} className="card-img-top teacherProfileImage" alt={props.profile}  />
            <div className="card-body teacherCardBody mt-5">
                <h4>{props.head}</h4>
                <h6 className="card-title my-2"><span >Name:</span> {props.staff_name}</h6><hr />
                <h6>Desig. {props.staff_designation}</h6>
                
            </div>
           
        </div>
      </div>
    </div>
  )
}

export default Non_Teaching_staff