import axios from 'axios';
import "./PrincipalMessage.css";
// import { useEffect } from 'react';
import React ,{ useState,useEffect } from 'react'
import Pageheader from './Pageheader';
import Subheading from './Subheading';
const PrincipalMessage = () => {
    const [message,setMessage] = useState({});
    useEffect(
        () => {
            axios.get("https://www.gmdc.edu.pk/admin-panel/principal_message_api.php")
            .then(response => {
                setMessage(response.data)                
            })
            .catch(error => {
                console.log(error)
            });

        }
    )
    
  return (
    <div className="container">
            <div className="row my-5 justify-content-evenly" id='pmessage'>
                <Pageheader title="Principal's Message" />
                {
                    message.length ? 
                    message.map(message => (
                        <>
                         <div className="col-lg-6 col-11" key={message.id}>
                            <div className="leftsectionprincipal">
                                <div className="principalImage ">
                                    <img 
                                    src={`https://www.gmdc.edu.pk/admin-panel/images/${message.principal_image}`} className="pImage" style={{width:"100%"}}/>
                                    <h3 className="my-3">{message.principal_name}</h3>
                                    <h5>{message.join_date}</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-11 ">
                            <div className="messagePrincipal">
                                {/* <h3 className="text-center">Principal's Message</h3> */}
                                <Subheading title="Principal's Message"/>
                                <p> {message.principal_message} </p>
                            </div>
                        </div>
                        </>
                    ))
                    : null
                }
            </div>
    </div>
  )
}

export default PrincipalMessage